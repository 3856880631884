$font: var(--font-default);

$white: #fff;
$bg-color: #fcfcfc;
$bg-color2: #f7f7f7;
$gray7: #272727;
$gray6: #67676c;
$gray5: #465457;
$gray4: #748487;
$gray3: #92a3a7;
$gray2: #b3c1c4;
$gray1-2bold: #d9d9d9;
$gray1-2: #e6e8eb;
$gray1: #e0e0e0;
$gray0: #ebebeb;
$gray0-2: #f0f1f2;
$gray0-1: #fafafa;
$yellow: #ffa600;
$pink: #ff158c;
$accent-darker: #352a7a;
$accent-shade: #f1efff;
$purple: #8800f8;
$purple1: #efecfe;
$purple2: #c3b9fd;
$purple3: #c9c0fa;
$blue: #5d43fa;
$violet: #8028ff;
$soft-pink: #ff336a;
$black-coral: #576467;
$black5: #0000008a;
$black-text: #465457;
$red: #e74c3c;
$light-graphite: #99a3aa;
/* Grafite Médio */
$middle-graphite: #77808b;
/* Neutros / Grafite Escuro */
$dark-graphite: #000000;
// $blue: #167FFD;
$violet1: #8038fb;

$hover-c1: #0000000a;
$info-text: #78868a;
$info-border: #bebebe;
$info-shadow: #00000012;

$gradient: linear-gradient(140deg, $blue, $purple);
