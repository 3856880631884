.info-box {
  background-color: #f5f5f7;
  padding: 12px 16px;
  border-radius: 8px;
  color: #333;
  font-size: 14px;
  width: 100%;
  margin-top: 20px;

  p {
    margin: 10px 0;
    white-space: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .note {
    color: #465457;
  }
}
