@import '../../styles/variables.scss';

.filters {
  &__loading {
    position: absolute;
  }

  &__chip-toggle-icon {
    &--expanded-more {
      transform: rotate(0deg);
      transition: transform 200ms ease;
      fill: $white;
    }

    &--expanded-less {
      transform: rotate(-180deg);
      transition: transform 200ms ease;
      fill: $gray5;
    }
  }
}

.info {
  color: $gray3 !important;
  font-size: 14px !important;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
}

.tooltip {
  position: relative;
  display: inline-block;

  .infoIcon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-right: 5px;
  }

  .tooltipText {
    visibility: hidden;
    width: 250px;
    background-color: #292929;
    color: #fff;
    text-align: left;
    padding: 8px;
    border-radius: 6px;
    position: absolute;
    left: 125%; // Moves it to the right of the icon
    top: 50%;
    transform: translateY(-50%); // Vertically centers it
    font-size: 12px;
    line-height: 1.4;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    white-space: normal;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    // Arrow pointing to the icon
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%; // Moves it to the left of the tooltip
      transform: translateY(-80%);
      border-width: 6px;
      border-style: solid;
      border-color: transparent #292929 transparent transparent;
    }
  }

  .infoIcon:hover + .tooltipText {
    visibility: visible;
    opacity: 1;
  }
}
