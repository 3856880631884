@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  color: $gray4 !important;
  font-size: 14px !important;
  padding-top: 2px;
  transition: color 0.3s ease-in-out;
}

/* Change label color when switch is checked */
.label-checked {
  color: $gray5 !important;
}

.switch-toggle {
  margin-left: -5px;
  text-align: left !important;
}

/* Default track background */
.MuiSwitch-track {
  background-color: $gray3 !important; // Default gray track
  opacity: 1 !important;
}

/* Change track background when the switch is checked */
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: $violet1 !important; // Violet when checked
  opacity: 1 !important;
}

/* Keep the switch slider (thumb) gray */
.MuiSwitch-thumb {
  background-color: $gray1 !important; // Keep thumb gray
}
