@import '../../../../styles/variables.scss';

.bar-chart {
  &__container {
    height: 260px;
    font-family: $font !important;
    font-weight: bold !important;

    // Bar chart axis labels style
    div > div > svg > g > g > g > text {
      font-family: $font !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      fill: $dark-graphite !important;
    }
  }

  &__chart-label {
    font-size: 12px;
    fill: #ffffff;
    font-weight: 700;
    letter-spacing: -0.01em;
  }

  &__tooltip {
    min-width: 160px;
    height: 64px;
    padding: 12px 16px;
    background-color: $dark-graphite;
    border-radius: 4px;
    color: #ffffff;

    &__title {
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
      white-space: nowrap;
      margin-bottom: 8px;
    }

    &__text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
    }
  }
}
